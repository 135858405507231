import { FETCH_PAGES } from '../Constants'

const initialState = {}

function pagesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PAGES:
            return action.payload
        default:
            return state
    }
}


export default pagesReducer