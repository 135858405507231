import { FETCH_SUBORDERS, FETCH_CURRENT_SUBORDERS, FETCH_PAST_SUBORDERS, RESET_SUBORDERS } from '../Constants'

const initialState = []

function suborderReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SUBORDERS:
            return action.payload
        case FETCH_CURRENT_SUBORDERS:
            return action.payload
        case FETCH_PAST_SUBORDERS:
            return action.payload
        case RESET_SUBORDERS:
            return action.payload
        default:
            return state
    }
}


export default suborderReducer