import { FETCH_VENDOR_MESSAGES } from '../Constants'

const initialState = []

function vendorMessageReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_VENDOR_MESSAGES:   
            return action.payload
        default:
            return state
    }
}


export default vendorMessageReducer