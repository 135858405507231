import { FETCH_CONFIGURATIONS, FETCH_CONFIGURATIONS_LISTENER } from '../Constants'

const initialState = []

function configurationReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CONFIGURATIONS:
            return action.payload
        case FETCH_CONFIGURATIONS_LISTENER:
            return action.payload
        default:
            return state
    }
}


export default configurationReducer