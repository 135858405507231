import { FETCH_MAIN_CATEGORIES, FETCH_MAIN_CATEGORIES_LISTENER } from '../Constants'

const initialState = []

function mainCategoryReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MAIN_CATEGORIES:
            return action.payload
        case FETCH_MAIN_CATEGORIES_LISTENER:
            return action.payload
        default:
            return state
    }
}


export default mainCategoryReducer