import { FETCH_ORDERS, FETCH_CURRENT_ORDERS, FETCH_PAST_ORDERS, RESET_ORDERS } from '../Constants'

const initialState = []

function orderReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ORDERS:
            return action.payload
        case FETCH_CURRENT_ORDERS:
            return action.payload
        case FETCH_PAST_ORDERS:
            return action.payload
        case RESET_ORDERS:
            return action.payload
        default:
            return state
    }
}


export default orderReducer