import { FETCH_EQUIPMENT_CATEGORIES, FETCH_EQUIPMENT_CATEGORIES_LISTENER } from '../Constants'

const initialState = []

function equipmentCategoryReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_EQUIPMENT_CATEGORIES:
            return action.payload
        case FETCH_EQUIPMENT_CATEGORIES_LISTENER:
            return action.payload
        default:
            return state
    }
}


export default equipmentCategoryReducer