import { FETCH_COUNTRY_CODES, FETCH_COUNTRY_CODES_LISTENER } from '../Constants'

const initialState = []

function countryCodeReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_COUNTRY_CODES:
            return action.payload
        case FETCH_COUNTRY_CODES_LISTENER:
            return action.payload
        default:
            return state
    }
}


export default countryCodeReducer