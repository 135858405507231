const Routes = {
    home: '/home',
    equipments : '/equipments',
    addEquipment: '/equipments/add',
    services: '/services',
    ratings: '/ratings',
    addService: '/services/add',
    support: '/support',
    report : '/reports',
    information: '/information',
    signin:'/',
    signup:'/sign-up',
    suborder: '/suborder',
    order:'/order',
    profile:'/vendors',
    categories: '/categories',
    backend: '/backend',
    mainCategories: '/mainCategories',
    privacyPolicy: '/privacy-policy',
    cookiePolicy: '/cookie-policy',
    vendorMessages:'/vendor-messages',
    userMessages: '/user-messages',
    customers:'/customers'
}

export default Routes