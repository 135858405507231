import { combineReducers } from 'redux';
import {LOGOUT} from '../Constants'
import blockedUserReducer from './blockedUserReducer';
import cartReducer from './cartReducer';
import configurationReducer from './configurationReducer';
import countryCodeReducer from './countryCodeReducer';
import customerReducer from './customerReducer';
import directionReducer from './directionReducer';
import equipmentCategoryReducer from './equipmentCategoryReducer';
import equipmentReducer from './equipmentReducer';
import informationPageReducer from './informationPageReducer';
import isApprovedReducer from './isApprovedReducer';
import isVendorReducer from './isVendorReducer';
import locationReducer from './locationReducer';
import mainCategoryReducer from './mainCategoryReducer';
import orderByIdReducer from './orderByIdReducer';
import orderReducer from './orderReducer';
import pagesReducer from './pageReducer';
import ratingReducer from './ratingReducer';
import serviceCategoryReducer from './serviceCategoryReducer';
import servicesReducer from './servicesReducer';
import singleEquipmentReducer from './singleEquipmentReducer'
import singleServiceReducer from './singleServiceReducer';
import suborderByIdReducer from './subOrderByIdReducer';
import suborderReducer from './subOrderReducer';
import userDetailsReducer from './userDetailsReducer';
import userMessageReducer from './userMessageReducer';
import uIdReducer from './userUidReducer';
// import vendorByIdReducer from './vendorByIdReducer';
import vendorDetailsReducer from './vendorDetailsReducer';
import vendorMessageReducer from './vendorMessageReducer';
import vendorsReducer from './vendorsReducer';

const appReducers = combineReducers({
    equipments: equipmentReducer,
    equipment: singleEquipmentReducer,
    equipmentCategories: equipmentCategoryReducer,
    uId: uIdReducer,
    user: userDetailsReducer,
    cart: cartReducer,
    direction: directionReducer,
    orders: orderReducer,
    order: orderByIdReducer,
    suborders: suborderReducer,
    suborder: suborderByIdReducer,
    locations: locationReducer,
    services: servicesReducer,
    service: singleServiceReducer,
    serviceCategories: serviceCategoryReducer,
    mainCategories: mainCategoryReducer,
    ratings: ratingReducer,
    isVendor: isVendorReducer,
    isApproved: isApprovedReducer,
    customers: customerReducer,
    countryCode: countryCodeReducer,
    vendors: vendorsReducer,
    configurations: configurationReducer,
    pages: pagesReducer,
    userMessages: userMessageReducer,
    vendorMessages: vendorMessageReducer,
    blockedUsers: blockedUserReducer,
    informationPages: informationPageReducer
})

const allReducers = (state,action) => {
    if(action.type === LOGOUT){
        state = undefined
    }
    return appReducers(state,action)
}

export default allReducers