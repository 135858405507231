import {FETCH_BLOCKED_USERS} from '../Constants'

const initialState = []

function blockedUserReducer(state=initialState,action){
    switch(action.type){
        case FETCH_BLOCKED_USERS:
            return action.payload
        default: 
            return state
    }
}


export default blockedUserReducer