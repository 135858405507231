import { FETCH_SERVICE_CATEGORIES, FETCH_SERVICES_CATEGORIES_LISTENER } from '../Constants'

const initialState = []

function serviceCategoryReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SERVICE_CATEGORIES:
            return action.payload
        case FETCH_SERVICES_CATEGORIES_LISTENER:
            return action.payload
        default:
            return state
    }
}


export default serviceCategoryReducer