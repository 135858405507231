import {FETCH_SUBORDER_BY_ID} from '../Constants'

const initialState = {}

function suborderByIdReducer(state=initialState,action){
    switch(action.type){
        case FETCH_SUBORDER_BY_ID:
            return action.payload
        default: 
            return state
    }
}


export default suborderByIdReducer