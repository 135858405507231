export const LOGOUT = 'LOGOUT'
export const FETCH_EQUIPMENTS = 'FETCH_EQUIPMENTS'
export const FETCH_SINGLE_EQUIPMENT = 'FETCH_SINGLE_EQUIPMENT'
export const FETCH_EQUIPMENT_CATEGORIES  = 'FETCH_EQUIPMENT_CATEGORIES'
export const FETCH_EQUIPMENT_CATEGORIES_LISTENER  = 'FETCH_EQUIPMENT_CATEGORIES_LISTENER'
export const SET_USER_UID = 'SET_USER_UID'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const FETCH_CART = 'FETCH_CART'
export const DIRECTION = 'DIRECTION'
export const FETCH_SERVICES = 'FETCH_SERVICES'
export const FETCH_SERVICES_CATEGORIES_LISTENER  = 'FETCH_SERVICES_CATEGORIES_LISTENER'
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_CURRENT_ORDERS = 'FETCH_CURRENT_ORDERS'
export const FETCH_PAST_ORDERS = 'FETCH_PAST_ORDERS'
export const RESET_ORDERS = 'RESET_ORDERS'
export const FETCH_ORDER_BY_ID = 'FETCH_ORDER_BY_ID'
export const FETCH_SUBORDERS = 'FETCH_SUBORDERS'
export const FETCH_CURRENT_SUBORDERS = 'FETCH_CURRENT_SUBORDERS'
export const FETCH_PAST_SUBORDERS = 'FETCH_PAST_SUBORDERS'
export const RESET_SUBORDERS = 'RESET_SUBORDERS'
export const FETCH_SUBORDER_BY_ID = 'FETCH_SUBORDER_BY_ID'
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS'
export const FETCH_LOCATIONS_LISTENER = 'FETCH_LOCATIONS_LISTENER'
export const FETCH_SINGLE_SERVICE = 'FETCH_SINGLE_SERVICE'
export const FETCH_SERVICE_CATEGORIES  = 'FETCH_SERVICE_CATEGORIES'
export const FETCH_MAIN_CATEGORIES = 'FETCH_MAIN_CATEGORIES'
export const FETCH_MAIN_CATEGORIES_LISTENER = 'FETCH_MAIN_CATEGORIES_LISTENER'
export const FETCH_RATINGS = 'FETCH_RATINGS'
export const IS_VENDOR = 'IS_VENDOR'
export const IS_APPROVED = 'IS_APPROVED'
export const SET_VENDOR_DETAILS = 'SET_VENDOR_DETAILS'
export const FETCH_COUNTRY_CODES = 'FETCH_COUNTRY_CODES'
export const FETCH_COUNTRY_CODES_LISTENER = 'FETCH_COUNTRY_CODES_LISTENER'
export const FETCH_VENDORS = 'FETCH_VENDORS'
export const FETCH_VENDOR_BY_ID = 'FETCH_VENDOR_BY_ID'
export const FETCH_CONFIGURATIONS = 'FETCH_CONFIGURATIONS'
export const FETCH_CONFIGURATIONS_LISTENER = 'FETCH_CONFIGURATIONS_LISTENER'
export const FETCH_PAGES = 'FETCH_PAGES'
export const FETCH_USER_MESSAGES = 'FETCH_USER_MESSAGES'
export const FETCH_VENDOR_MESSAGES = 'FETCH_VENDOR_MESSAGES'
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'
export const FETCH_BLOCKED_USERS = 'FETCH_BLOCKED_USERS'
export const FETCH_INFORMATION_PAGES = 'FETCH_INFORMATION_PAGES'