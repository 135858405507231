import { FETCH_VENDORS,SET_VENDOR_DETAILS } from '../Constants'

const initialState = []

function vendorsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_VENDORS:
            return action.payload
        case SET_VENDOR_DETAILS:
            return action.payload
        default:
            return state
    }
}


export default vendorsReducer