import { FETCH_INFORMATION_PAGES } from '../Constants'

const initialState = []

function informationPageReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_INFORMATION_PAGES:
            return action.payload
        default:
            return state
    }
}


export default informationPageReducer