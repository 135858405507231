import React, { lazy, Suspense, useEffect } from 'react'
import { Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import firebase from './projectFiles/firebase/config'
import { userCollection } from './projectFiles/firebase/Constants'
import { setUserUid } from './projectFiles/store/actions/uIdAction'
import { setUserDetails } from './projectFiles/store/actions/userAction'
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import SuspenseLoader from './projectFiles/materialUI/generalComponents/FallbackSuspenseLoader'
// route import
import AppRoutes from './projectFiles/General Functions/Routes'

// general functions
import ChunkLoading from './projectFiles/General Functions/ChunkLoading'
import Console from './projectFiles/General Functions/Console'

// material ui imports
import { create } from 'jss';
import rtl from 'jss-rtl';
import { ThemeProvider, createMuiTheme } from '@material-ui/core'
import Theme from './projectFiles/materialUI/Theme'
// import SuspenseLoader from './projectFiles/materialUI/generalComponents/SuspenseLoader'

// component loading
const SignUp = lazy(() => ChunkLoading(() => import('./projectFiles/components/SignUp')))
const SignIn = lazy(() => ChunkLoading(() => import('./projectFiles/components/SignIn')))
const MainApp = lazy(() => ChunkLoading(() => import('./projectFiles/components/MainApp')))


const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


function App(props) {
  const { direction } = props


  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged(user => {
  //     if (user) {
  //       console.log('run again')
  //       const uId = user.uid
  //       firebase.firestore().collection(userCollection).doc(uId).get().then((doc) => {
  //         const data = doc.data()
  //         setUserDetails(data)
  //       }).then(() => {
  //         if (firebase.messaging.isSupported()) {
  //           firebase.messaging().requestPermission().then(() => {
  //             firebase.messaging().getToken().then((token) => {
  //               firebase.firestore().collection(userCollection).doc(uId).update({
  //                 fcmTokens: firebase.firestore.FieldValue.arrayUnion(token)
  //               }).then(() => {
  //                 Console('token added')
  //               }).catch(err => Console(err))
  //             }).catch(err => {
  //               Console(err)
  //             })
  //           }).catch(err => Console(err))
  //         }
  //       }).catch(err => {
  //         Console(err)
  //       })
  //     } else {
  //       firebase.auth().signInAnonymously().catch(err => { Console(err) })
  //     }
  //   })
  //   return () => {
  //   }
  // }, [])

  return (
    <ThemeProvider theme={createMuiTheme({ ...Theme, direction: direction })}>
      <StylesProvider jss={jss}>
        <Suspense fallback={<div style={{width:'100vw',height:'100vh'}}><SuspenseLoader /></div>}>
          <Router>
            <Switch>
              <Route exact path={AppRoutes.signup} component={SignUp}></Route>
              <Route exact path={AppRoutes.signin} component={SignIn}></Route>
              <Route path='*' component={MainApp}></Route>
            </Switch>
          </Router>
        </Suspense>
      </StylesProvider>
    </ThemeProvider>

  );
}

const mapStateToProps = state => {
  return {
    direction: state.direction
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserUid: (value) => dispatch(setUserUid(value)),
    setUserDetails: (value) => dispatch(setUserDetails(value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
