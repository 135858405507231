import {FETCH_ORDER_BY_ID} from '../Constants'

const initialState = []

function orderByIdReducer(state=initialState,action){
    switch(action.type){
        case FETCH_ORDER_BY_ID:
            return action.payload
        default: 
            return state
    }
}


export default orderByIdReducer