import { FETCH_USER_MESSAGES } from '../Constants'

const initialState = []

function userMessageReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_MESSAGES:   
            return action.payload
        default:
            return state
    }
}


export default userMessageReducer